.add-new-post__editor .ql-container,
.add-new-post__editor .ql-editor {
  /* min-height: 400px; */
}
.preview > p {margin-bottom: 0}
.preview > p > strong {
  font-weight: bold;
}

.ql-container.ql-snow {
  height: 600px;
  overflow: scroll;
}