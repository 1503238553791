.parent{
    display: grid;
    place-items: center;
    margin: 30px 0px;
}

.spinner {
  width:50px;
  height:50px;
  border-radius:50%;
  background:#514b82;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 70deg,#0000 71deg 90deg),
    radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 calc(100% - 8px));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation:s5 1s infinite ;
}
@keyframes s5 {to{transform: rotate(.5turn)}}


.login {
  width: 40px;
  height: calc(80px*0.866);
  clip-path: polygon(0 0,100% 100%,0 100%,100% 0);
  position: relative;
  animation:co9 2s infinite linear; 
  overflow: hidden;
}
.login:before{
  content: "";
  position: absolute;
  inset:-150% -150%;
  background:repeating-conic-gradient(from 30deg,#FFABAB 0 60deg,#ABE4FF 0 120deg,#FF7373 0 180deg);
  animation:inherit;
  animation-direction: reverse; 
}
@keyframes co9 {
  100% {transform: rotate(360deg)}
}